export const fetchSectionNav = (action) => ({
    type: "FETCH_SECTION_NAV_SUCCESS",
    data: action.data ? action.data : null,
    key: action.key ? action.key : null,
});
export const setActiveSectionNav = (action) => ({
    type: "SET_ACTIVE_SECTION_NAV_SUCCESS",
    data: action ? action : null,
});
export const clearActiveSectionNav = (action) => ({
    type: "CLEAR_ACTIVE_SECTION_NAV_SUCCESS",
});
export const sectionNavError = (action) => ({
    type: "SECTION_NAV_ERROR",
    error: action ? action : null,
});

export default {
    fetchSectionNav,
    setActiveSectionNav,
    clearActiveSectionNav,
    sectionNavError,
};
