const initialState = {
    top: null,
    middle: null,
    bottom: null,
    error: null,
};

const aside = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_ASIDE_SUCCESS":
            return {
                ...state,
                top: action.top ? action.top : null,
                middle: action.middle ? action.middle : null,
                bottom: action.bottom ? action.bottom : null,
            };
        case "CLEAR_ASIDE_SUCCESS":
            return {
                top: null,
                middle: null,
                bottom: null,
                error: null,
            };
        default:
            return state;
    }
};

export default aside;
