import React from "react";

function Loader(props) {
    const className = "fas fa-" + props.size + "x fa-spinner fa-pulse text-primary";

    return (
        <div className="text-center py-4">
            <i className={className}></i>
        </div>
    );
}

export default Loader;
