export const fetchRankingPage = (action) => ({
    type: "FETCH_RANKING_PAGE_SUCCESS",
    data: action.data ? action.data : null,
    key: action.key ? action.key : null,
});
export const setActiveRankingPage = (action) => ({
    type: "SET_ACTIVE_RANKING_PAGE_SUCCESS",
    data: action ? action : null,
});
export const clearActiveRankingPage = (action) => ({
    type: "CLEAR_ACTIVE_RANKING_PAGE_SUCCESS",
});
export const rankingPageError = (action) => ({
    type: "RANKING_PAGE_ERROR",
    error: action ? action : null,
});

export default {
    fetchRankingPage,
    setActiveRankingPage,
    clearActiveRankingPage,
    rankingPageError,
};
