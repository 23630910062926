import React from "react";

import TypeHtml from "./TypeHtml";
import TypeBanners from "./TypeBanners";
import TypeCards from "./TypeCards";

const AsideType = ({ type, value }) => {
    return (
        <>
            {type === "html" && <TypeHtml value={value} />}
            {type === "banners" && <TypeBanners value={value} />}
            {type === "cards" && <TypeCards value={value} />}
        </>
    );
};

export default AsideType;
