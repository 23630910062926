import React, { useEffect, useState, useContext } from "react";
// import axios from "axios";

import Cards from "../../Cards/Cards";
import Loader from "../../../components/Loader/Loader";

import RKSAllPl from "../../../assets/data/pl/cards/rks-all.json";
import RSWCriteriaPl from "../../../assets/data/pl/cards/rsw-criteria.json";
import RSWTypePl from "../../../assets/data/pl/cards/rsw-type.json";
import RKSAllEn from "../../../assets/data/en/cards/rks-all.json";
import RSWCriteriaPlEn from "../../../assets/data/en/cards/rsw-criteria.json";
import RSWTypeEn from "../../../assets/data/en/cards/rsw-type.json";
import { LangContext } from '../../../context/LanguageContext';

const dataPl = {
  "rks-all": RKSAllPl,
  "rsw-criteria": RSWCriteriaPl,
  "rsw-type": RSWTypePl,
};

const dataEn = {
  "rks-all": RKSAllEn,
  "rsw-criteria": RSWCriteriaPlEn,
  "rsw-type": RSWTypeEn,
};

const TypeCards = ({ value }) => {
    const { language } = useContext(LangContext);
    const [isError, setIsError] = useState();
    const cards = language === 'pl' ? dataPl[value] : dataEn[value];

    useEffect(() => {
        if (!value) {
            setIsError("Wystąpił błąd.");
        }
    }, [value]);

    if (!cards) {
        return <>{isError ? "null" : <Loader />}</>;
    }
    return <Cards value={cards.cards} aside />;
};

export default TypeCards;
