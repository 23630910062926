const initialState = {
    pl: {
        global: {
            more: "Zobacz więcej...",
            share: "Udostępnij",
        },
        aside: {
            global1: "Partnerzy Merytoryczni <br/> Rankingu Szkół Wyższych 2022",
            global2: "Partner <br/> Rankingu Szkół Wyższych 2022",
            global3: "Partner Gali<br/> Rankingu Szkół Wyższych 2022",
        },
        sectionNav: {
            redmore: "tabele rankingowe 2022",
        },
        subNav: [
            {
                groups: [
                    {
                        color: "#ed1b3a",
                        header: {
                            value: "Portal edukacyjny",
                            link: "http://perspektywy.pl/portal/",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Aktualności edukacyjne",
                                link: "http://perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=8&Itemid=119",
                                target: "_blank",
                            },
                        ],
                    },
                    {
                        color: "#b85e29",
                        header: {
                            value: "Dla Gimnazjalistów",
                            link: null,
                            target: null,
                        },
                        items: [
                            {
                                value: "Licea",
                                link: "http://licea.perspektywy.pl/2021/",
                                target: "_blank",
                            },
                            {
                                value: "Technika",
                                link: "http://technika.perspektywy.pl/2021/",
                                target: "_blank",
                            },
                            {
                                value: "Licea z maturą międzynarodową",
                                link: "http://ib.perspektywy.pl/",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#583",
                        header: {
                            value: "Dla Maturzystów",
                            link: "http://matura.perspektywy.pl",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Matura 2021",
                                link: "http://matura.perspektywy.pl",
                                target: "_blank",
                            },
                            {
                                value: "Poradnik maturzysty",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=201&Itemid=370",
                                target: "_blank",
                            },
                        ],
                    },
                    {
                        color: "#216998",
                        header: {
                            value: "Targi Edukacyjne",
                            link: "http://www.salonmaturzystow.pl/",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Salon Maturzystów Perspektywy 2021",
                                link: "http://salonmaturzystow.pl/",
                                target: "_blank",
                            },
                            {
                                value: "XXXI Salon Edukacyjny",
                                link: "http://salon.perspektywy.pl/",
                                target: "_blank",
                            },
                            {
                                value: "Perspektywy 2021",
                                link: "http://salon.perspektywy.pl/",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#f60",
                        header: {
                            value: "Uczelnie",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=4&Itemid=115",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Uczelnie publiczne",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=4&Itemid=115",
                                target: "_blank",
                            },
                            {
                                value: "Uczelnie niepubliczne",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=5&Itemid=116",
                                target: "_blank",
                            },
                            {
                                value: "Publiczne Uczelnie Zawodowe",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=80&Itemid=224",
                                target: "_blank",
                            },
                            {
                                value: "Uczelniana mapa Polski",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=1&Itemid=117",
                                target: "_blank",
                            },
                        ],
                    },
                    {
                        color: "#9a1c28",
                        header: {
                            value: "Wybory Rektorów",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=191&Itemid=366",
                            target: "_blank",
                        },
                        items: null,
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#014b6e",
                        header: {
                            value: "Studia w Polsce",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2&Itemid=113#top-wyszukiwarka",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Pierwszego stopnia",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2&Itemid=113#top-wyszukiwarka",
                                target: "_blank",
                            },
                            {
                                value: "Magisterskie drugiego stopnia",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=72&Itemid=218",
                                target: "_blank",
                            },
                            {
                                value: "Jednolite magisterskie",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2&Itemid=113#top-wyszukiwarka",
                                target: "_blank",
                            },
                            {
                                value: "Podyplomowe",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=40&Itemid=191",
                                target: "_blank",
                            },
                            {
                                value: "Doktoranckie",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=36&Itemid=188",
                                target: "_blank",
                            },
                            {
                                value: "MBA",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=3183%3Anajlepsze-programy-mba-w-polsce-2016&catid=213&Itemid=413",
                                target: "_blank",
                            },
                            {
                                value: "Kierunki studiów",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=22&Itemid=171",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#be1a8d",
                        header: {
                            value: "Ranking Liceów Ogólnokształcących 2021",
                            link: "https://licea.perspektywy.pl/2021/tabele/ranking-glowny-liceow",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Ranking Maturalny LO 2021",
                                link: "https://licea.perspektywy.pl/2021/tabele/ranking-maturalny-liceow",
                                target: "_blank",
                            },
                            {
                                value: "Ranking Szkół Olimpijskich 2021",
                                link: "https://licea.perspektywy.pl/2021/tabele/ranking-szkol-olimpijskich",
                                target: "_blank",
                            },
                            {
                                value: "Ranking Szkół Warszawskich 2016",
                                link: "http://rankingwarszawski.perspektywy.pl/",
                                target: "_blank",
                            },
                            {
                                value: "Ranking Liceów STEM 2016",
                                link: "http://rankingstem.perspektywy.pl/",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#4527A0",
                        header: {
                            value: "Ranking Techników 2021",
                            link: "http://technika.perspektywy.pl/2021",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Ranking Maturalny Techników 2021",
                                link: "https://technika.perspektywy.pl/2021/tabele/ranking-maturalny-technikow",
                                target: "_blank",
                            },
                            {
                                value: "Ranking Szkół Olimpijskich 2021",
                                link: "https://technika.perspektywy.pl/2021/tabele/ranking-szkol-olimpijskich",
                                target: "_blank",
                            },
                            {
                                value: "Ranking Techników Warszawskich 2016",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2735:ranking-technikow-warszawskich-2016&catid=195&Itemid=367",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#be8d32",
                        header: {
                            value: "Ranking Szkół Wyższych 2021",
                            link: "http://ranking.perspektywy.pl/",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Uczelnie akademickie",
                                link: "http://ranking.perspektywy.pl/2021/ranking/ranking-uczelni-akademickich",
                                target: "_blank",
                            },
                            {
                                value: "Niepubliczne uczelnie magisterskie",
                                link: "http://ranking.perspektywy.pl/2021/ranking/ranking-uczelni-niepublicznych",
                                target: "_blank",
                            },
                            {
                                value: "Publiczne Uczelnie Zawodowe",
                                link: "http://ranking.perspektywy.pl/2021/ranking/ranking-puz",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#3c83aa",
                        header: {
                            value: "Ranking Studiów Inżynierskich 2021",
                            link: "http://engineering.perspektywy.pl/2021/",
                            target: "_blank",
                        },
                        items: null,
                    },
                    {
                        color: "#1F8BD6",
                        header: {
                            value: "Ranking Kierunków Studiów 2021",
                            link: "http://ranking.perspektywy.pl/2021/ranking/ranking-kierunkow-studiow",
                            target: "_blank",
                        },
                        items: null,
                    },
                    {
                        color: "#a20353",
                        header: {
                            value: "Ranking MBA 2018",
                            link: "http://mba.perspektywy.pl/",
                            target: "_blank",
                        },
                        items: null,
                    },
                ],
            },
        ],
        table: {
            total: "Wyświetlanie rekordów:",
            searchBar: "Wyszukaj",
            toggleHide: "Zwiń tabelę",
            toggleShow: "Rozwiń tabelę",
        },
    },
    en: {
        global: {
            more: "Read more...",
            share: "Share",
        },
        aside: {
            global1: "Content Partners <br/> University Ranking 2022",
            global2: "Partner <br/> University Ranking 2022",
            global3: "Gala Partner<br/> University Ranking 2022",
        },
        sectionNav: {
            redmore: "2022 leaderboards",
        },
        subNav: [
            {
                groups: [
                    {
                        color: "#ed1b3a",
                        header: {
                            value: "Educational Portal",
                            link: "http://perspektywy.pl/portal/",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Education news",
                                link: "http://perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=8&Itemid=119",
                                target: "_blank",
                            },
                        ],
                    },
                    {
                        color: "#b85e29",
                        header: {
                            value: "For Secondary School Students",
                            link: null,
                            target: null,
                        },
                        items: [
                            {
                                value: "High Schools",
                                link: "http://licea.perspektywy.pl/2021/",
                                target: "_blank",
                            },
                            {
                                value: "Technical Schools",
                                link: "http://technika.perspektywy.pl/2021/",
                                target: "_blank",
                            },
                            {
                                value: "High Schools with IB Diploma",
                                link: "http://ib.perspektywy.pl/",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#583",
                        header: {
                            value: "For High School Graduates",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=205&Itemid=369",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Matriculation Examination 2021",
                                link: "http://matura.perspektywy.pl",
                                target: "_blank",
                            },
                            {
                                value: "Guide for High School Graduates",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=201&Itemid=370",
                                target: "_blank",
                            },
                        ],
                    },
                    {
                        color: "#216998",
                        header: {
                            value: "Education Fairs",
                            link: "http://www.salonmaturzystow.pl/",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Poland International Perspektywy Education Fair 2020",
                                link: "http://www.salonmaturzystow.pl/",
                                target: "_blank",
                            },
                            {
                                value: "XXXI Education Fairs",
                                link: "http://www.salon.perspektywy.pl/",
                                target: "_blank",
                            },
                            {
                                value: "Perspektywy 2021",
                                link: "http://salon.perspektywy.pl/",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#f60",
                        header: {
                            value: "Universities",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=4&Itemid=115",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Public Universities",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=4&Itemid=115",
                                target: "_blank",
                            },
                            {
                                value: "Private Universities",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=5&Itemid=116",
                                target: "_blank",
                            },
                            {
                                value: "State Higher Vocational Schoolse",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=80&Itemid=224",
                                target: "_blank",
                            },
                            {
                                value: "Map of Polish Universities",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=1&Itemid=117",
                                target: "_blank",
                            },
                        ],
                    },
                    {
                        color: "#9a1c28",
                        header: {
                            value: "Rectors Elections",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=191&Itemid=366",
                            target: "_blank",
                        },
                        items: null,
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#014b6e",
                        header: {
                            value: "Studies in Poland",
                            link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2&Itemid=113#top-wyszukiwarka",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "First Cycle Studies",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2&Itemid=113#top-wyszukiwarka",
                                target: "_blank",
                            },
                            {
                                value: "Second Cycle Studies",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=72&Itemid=218",
                                target: "_blank",
                            },
                            {
                                value: "Master Studies",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2&Itemid=113#top-wyszukiwarka",
                                target: "_blank",
                            },
                            {
                                value: "Postgraduate Studies",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=40&Itemid=191",
                                target: "_blank",
                            },
                            {
                                value: "Doctoral Studies",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=category&layout=blog&id=36&Itemid=188",
                                target: "_blank",
                            },
                            {
                                value: "MBA",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=3183%3Anajlepsze-programy-mba-w-polsce-2016&catid=213&Itemid=413",
                                target: "_blank",
                            },
                            {
                                value: "Fields of Studies",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=22&Itemid=171",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#be1a8d",
                        header: {
                            value: "Ranking of High Schools 2021",
                            link: "https://licea.perspektywy.pl/2021/tabele/ranking-glowny-liceow",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Ranking of High Schools 2021",
                                link: "https://licea.perspektywy.pl/2021/tabele/ranking-maturalny-liceow",
                                target: "_blank",
                            },
                            {
                                value: "Ranking of Olympiad High Schools 2021",
                                link: "https://licea.perspektywy.pl/2021/tabele/ranking-szkol-olimpijskich",
                                target: "_blank",
                            },
                            {
                                value: "Ranking of Warsaw High Schools 2016",
                                link: "http://rankingwarszawski.perspektywy.pl/",
                                target: "_blank",
                            },
                            {
                                value: "Ranking of High Schools STEM 2016",
                                link: "http://rankingstem.perspektywy.pl/",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#4527A0",
                        header: {
                            value: "Ranking of Technical Schools 2021",
                            link: "http://technika.perspektywy.pl/2021",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Ranking of Technical Schools 2021",
                                link: "https://technika.perspektywy.pl/2021/tabele/ranking-maturalny-technikow",
                                target: "_blank",
                            },
                            {
                                value: "Ranking of Olympiad Schools 2021",
                                link: "https://technika.perspektywy.pl/2021/tabele/ranking-szkol-olimpijskich",
                                target: "_blank",
                            },
                            {
                                value: "Ranking of Warsaw Technical Schools 2016",
                                link: "http://www.perspektywy.pl/portal/index.php?option=com_content&view=article&id=2735:ranking-technikow-warszawskich-2016&catid=195&Itemid=367",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#be8d32",
                        header: {
                            value: "Ranking of HEIs 2021",
                            link: "http://ranking.perspektywy.pl/",
                            target: "_blank",
                        },
                        items: [
                            {
                                value: "Academic Universities",
                                link: "http://ranking.perspektywy.pl/2021/ranking/ranking-uczelni-akademickich",
                                target: "_blank",
                            },
                            {
                                value: "Private Master Universities",
                                link: "http://ranking.perspektywy.pl/2021/ranking/ranking-uczelni-niepublicznych",
                                target: "_blank",
                            },
                            {
                                value: "State Higher Vocational Schools",
                                link: "http://ranking.perspektywy.pl/2021/ranking/ranking-puz",
                                target: "_blank",
                            },
                        ],
                    },
                ],
            },
            {
                groups: [
                    {
                        color: "#3c83aa",
                        header: {
                            value: "Ranking of Engineering Studies 2021",
                            link: "http://engineering.perspektywy.pl/2021/",
                            target: "_blank",
                        },
                        items: null,
                    },
                    {
                        color: "#1F8BD6",
                        header: {
                            value: "Ranking by Subject21",
                            link: "http://ranking.perspektywy.pl/2021/ranking/ranking-kierunkow-studiow",
                            target: "_blank",
                        },
                        items: null,
                    },
                    {
                        color: "#a20353",
                        header: {
                            value: "Ranking MBA 2018",
                            link: "http://mba.perspektywy.pl/",
                            target: "_blank",
                        },
                        items: null,
                    },
                ],
            },
        ],
        table: {
            total: "Showing rows:",
            searchBar: "Search",
            toggleHide: "collapse the table",
            toggleShow: "expand the table",
        },
    },
};

const translations = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default translations;
