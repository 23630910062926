const initialState = {
    error: null,
    toggle: false,
    data: {},
    active: null,
};

const rankingPage = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_RANKING_SUCCESS":
            return {
                ...state,
                error: null,
                data: {
                    ...state.data,
                    [action.key]: action.data ? action.data : null,
                },
            };
        case "SET_ACTIVE_RANKING_SUCCESS":
            return {
                ...state,
                error: null,
                active: action.data ? action.data : initialState.active,
            };
        case "CLEAR_ACTIVE_RANKING_SUCCESS":
            return {
                ...state,
                error: null,
                active: null,
            };
        case "RANKING_COLUMNS_FORMATER":
            return {
                ...state,
                active: {
                    ...state.active,
                    columns: action.columns ? action.columns : state.active.columns,
                },
            };
        case "RANKING_TABLE_PAGE_CHANGE":
            return {
                ...state,
                active: {
                    ...state.active,
                    settings: {
                        ...state.active.settings,
                        activePage: action.activePage ? action.activePage : state.active.settings.activePage,
                    },
                    columns: action.columns ? action.columns : state.active.columns,
                },
            };
        case "RANKING_TABLE_SIZE_PER_PAGE_CHANGE":
            return {
                ...state,
                active: {
                    ...state.active,
                    settings: {
                        ...state.active.settings,
                        sizePerPage: action.sizePerPage ? action.sizePerPage : state.active.settings.sizePerPage,
                    },
                    columns: action.columns ? action.columns : state.active.columns,
                },
            };
        case "RANKING_TABLE_SET_FULL_WIDTH":
            return {
                ...state,
                toggle: action.toggle ? action.toggle : false,
            };
        default:
            return state;
    }
};

export default rankingPage;
