import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";

import { setActiveSectionNav } from "../../redux/actions/sectionNav";
import { LangContext } from '../../context/LanguageContext';

const Contact = () => {
    const { language } = useContext(LangContext);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setActiveSectionNav("rsw"));
        // eslint-disable-next-line
    }, []);

    return (
        <div className="contact">
            <h1 className="contact__title">{language === "en" ? "Contact" : "Kontakt"}</h1>

            <p className="mb-4">
                {language === "en"
                    ? "Please send any questions regarding the Ranking by e-mail:"
                    : "Wszelkie pytania i wątpliwości dotyczące Rankingu prosimy zgłaszać mailem:"}{" "}
                <a href="mailto:ranking@perspektywy.pl">ranking@perspektywy.pl</a>
            </p>

            <p>
                <b className="f-125">{language === "en" ? "Rankings" : "Rankingi"}</b>
                <br />
                <a href="mailto:ranking@perspektywy.pl">ranking@perspektywy.pl</a>
            </p>
            <p>
                <b className="f-125">{language === "en" ? "Editorial office" : "Redakcja"}</b>
                <br />
                <a href="mailto:redakcja@perspektywy.pl">redakcja@perspektywy.pl</a>
            </p>
            <p>
                <b className="f-125">{language === "en" ? "Advertisement" : "Reklama"}</b>
                <br />
                <a href="mailto:reklama@perspektywy.pl">reklama@perspektywy.pl</a>
            </p>
            <p>
                <b className="f-125">{language === "en" ? "IT" : "Dział IT"}</b>
                <br />
                <a href="mailto:it@perspektywy.pl">it@perspektywy.pl</a>
            </p>

            <address className="mt-4">
                <strong className="f-125">Wydawnictwo Edukacyjne Perspektywy Press Sp. z o.o.</strong>
                <br />
                ul. Nowogrodzka 31
                <br />
                00-511 Warszawa PL
                <br />
                Tel. <a href="tel:+48226295326">+48 22 628 58 62</a>
                <br />
                <a href="http://perspektywy.pl/portal/" target="_blank" rel="noopener noreferrer">
                    www.perspektywypress.pl
                </a>
            </address>
        </div>
    );
};

export default Contact;
