import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import "./style.scss";

//core components
import ShareButton from "../../components/ShareButton/ShareButton";
import Loader from "../../components/Loader/Loader";

import { setActiveSectionNav } from "../../redux/actions/sectionNav";
import { lang } from "../../assets/js/variables";
import { LangContext } from '../../context/LanguageContext';

const Gallery = () => {
    const dispatch = useDispatch();
    const [galleryData, setGalleryData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { language } = useContext(LangContext);

    useEffect(() => {
        dispatch(setActiveSectionNav("rsw"));
        axios
            .get(process.env.REACT_APP_API + lang[language].GALLERY)
            .then(function (response) {
                setGalleryData(response.data);
                setIsLoading(false);
            })
            .catch(function (error) {
                if (error.response && error.response.data.message) {
                    setIsError(error.response.data.message);
                } else {
                    setIsError("error");
                }
            });
        // eslint-disable-next-line
    }, []);

    if (isLoading) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return (
        <>
            <ShareButton />
            <div className="mb-3 d-flex">
                <i>fot. Perspektywy, Anita Kot</i>
            </div>
            <div className="grid">
                {galleryData.map((e, index) => (
                    <div className="grid-item text-center" key={index}>
                        <a href={e.link || e.mini} data-toggle="lightbox" data-gallery="gallery">
                            <img src={e.mini} className="img-fluid" alt={e.alt} />
                        </a>
                    </div>
                ))}
            </div>
            {window["masonryInit"]()}
        </>
    );
};

export default Gallery;
