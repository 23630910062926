import React, { useContext } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { LangContext } from '../../../context/LanguageContext';

const SubNav = ({ subNavToggle }) => {
    const { language } = useContext(LangContext);
    const translationState = useSelector((state) => state.translations);
    const translationContent = translationState[language].subNav;

    return (
        <div className="subNav__container d-none- d-md-block" data-issubnav={subNavToggle}>
            <div className="subNav">
                <div className="row">
                    {translationContent &&
                        translationContent.map((e, index) => (
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                                {e.groups.map((group, index) => (
                                    <div className="subNav__group" style={{ color: group.color }} key={index}>
                                        <div className="group__header">
                                            {group.header.link ? (
                                                <a
                                                    className="group__header--title"
                                                    href={group.header.link}
                                                    target={group.header.target}
                                                >
                                                    {group.header.value}
                                                </a>
                                            ) : (
                                                <p className="group__header--title">{group.header.value}</p>
                                            )}
                                        </div>

                                        {group.items && (
                                            <ul className="group__items" style={{ borderLeftColor: group.color }}>
                                                {group.items.map((item, index) => (
                                                    <li key={index}>
                                                        <a
                                                            className="group__items--link"
                                                            href={item.link}
                                                            target={item.target}
                                                        >
                                                            {item.value}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default SubNav;
