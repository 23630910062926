const initialState = {
    error: null,
    data: {},
    active: null,
};

const sectionNav = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_SECTION_NAV_SUCCESS":
            return {
                ...state,
                error: null,
                data: {
                    ...state.data,
                    [action.key]: action.data ? action.data : null,
                },
            };
        case "SET_ACTIVE_SECTION_NAV_SUCCESS":
            return {
                ...state,
                error: null,
                active: action.data ? action.data : null,
            };
        case "CLEAR_ACTIVE_SECTION_NAV_SUCCESS":
            return {
                ...state,
                error: null,
                active: null,
            };
        case "SECTION_NAV_ERROR":
            return {
                ...state,
                error: action.error ? action.error : null,
            };
        default:
            return state;
    }
};

export default sectionNav;
