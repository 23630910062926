import { combineReducers } from "redux";
import translations from "./translations";
import rankingPage from "./rankingPage";
import ranking from "./ranking";
import sectionNav from "./sectionNav";
import aside from "./aside";

export default combineReducers({
    translations,
    rankingPage,
    sectionNav,
    ranking,
    aside,
});
