import React from "react";
import { Link } from 'react-router-dom';

const Icon = ({ value, icon, link, target, router }) => {
    return (
        <div className="navItem__content navItem__content--icon">
            {router ? (
                <Link to={link ? link : "/"}>
                    <div className="text-center">
                        <i className={"fas fa-2x " + (icon ? icon : "fa fa-university")}></i>
                    </div>
                    <span className="navItem__text">{value}</span>
                </Link>
            ) : (
                <a href={link} target={target}>
                    <div className="text-center">
                        <i className={"fas fa-2x " + (icon ? icon : "fa fa-university")}></i>
                    </div>
                    <span className="navItem__text">{value}</span>
                </a>
            )}
        </div>
    );
};

export default Icon;
