import React, { useContext } from "react";
import { useSelector } from "react-redux";
import "./Styles/shareButton.scss";
import { createBrowserHistory } from "history";
import { LangContext } from '../../context/LanguageContext';

const ShareButton = (props) => {
    const history = createBrowserHistory();
    const { language } = useContext(LangContext);
    const translationState = useSelector((state) => state.translations);

    return (
        <div className={"d-flex my-2 " + (props.justify ? props.justify : "")}>
            <div className="shere__button text-center py-1">
                <a
                    className="py-1"
                    role="button"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_SHARE}${history.location.pathname}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fab fa-facebook-f f-90"></i>
                    <span className="d-inline-block text-uppercase">
                        <b>&nbsp;{translationState[language].global.share}</b>
                    </span>
                </a>
            </div>
        </div>
    );
};

export default ShareButton;
