import React, {useState, useEffect, useContext} from "react";
import { useSelector } from "react-redux";
// import axios from "axios";

import Loader from "../../../../components/Loader/Loader";
import Cards from "../../../../containers/Cards/Cards";

import RKSAllPl from "../../../../assets/data/pl/cards/rks-all.json";
import RSWCriteriaPl from "../../../../assets/data/pl/cards/rsw-criteria.json";
import RSWTypePl from "../../../../assets/data/pl/cards/rsw-type.json";
import RKSAllEn from "../../../../assets/data/en/cards/rks-all.json";
import RSWCriteriaPlEn from "../../../../assets/data/en/cards/rsw-criteria.json";
import RSWTypeEn from "../../../../assets/data/en/cards/rsw-type.json";

import { LangContext } from "../../../../context/LanguageContext"

import "./style.scss";

const RankingCards = () => {
    const activeContentState = useSelector((state) => state.rankingPage.active.content);
    const [cardsState, setCardsState] = useState();
    const [isError, setIsError] = useState();
    const { language } = useContext(LangContext)

    const dataPl = {
        "rks-all": RKSAllPl,
        "rsw-criteria": RSWCriteriaPl,
        "rsw-type": RSWTypePl,
    };

    const dataEn = {
        "rks-all": RKSAllEn,
        "rsw-criteria": RSWCriteriaPlEn,
        "rsw-type": RSWTypeEn,
    };

    useEffect(() => {
        if (activeContentState.value) {
            if (language === "en") {
                setCardsState(dataEn[activeContentState.value]);
            } else {
                setCardsState(dataPl[activeContentState.value]);
            }
            // axios
            //     .get(process.env.REACT_APP_FOLDER + "data/" + language + "/cards/" + activeContentState.value + ".json")
            //     .then(function (response) {
            //         setCardsState(response.data);
            //     })
            //     .catch(function (error) {
            //         if (error.response && error.response.data.message) {
            //             setIsError(error.response.data.message);
            //         } else {
            //             setIsError("error");
            //         }
            //     });
        } else {
            setIsError("Wystąpił błąd.");
        }
        // eslint-disable-next-line
    }, [activeContentState]);

    if (isError || !cardsState) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return (
        <div className="rankingCards">
            {cardsState.title && <h2 className="rankingCards__title">{cardsState.title}</h2>}

            <Cards value={cardsState.cards} />
        </div>
    );
};

export default RankingCards;
