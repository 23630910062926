import React from "react";
import "./style.scss";

const Burger = ({ id, target, onClick, subNavToggle, white, className }) => {
    return (
        <>
            {onClick ? (
                <button
                    id={id}
                    className={"ownToggler " + (white ? "white " : "") + (className ? className : "")}
                    type="button"
                    aria-expanded={subNavToggle}
                    onClick={onClick}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            ) : (
                <button
                    id={id}
                    className={"navbar-toggler ml-auto " + (white ? " white " : "") + (className ? className : "")}
                    type="button"
                    data-toggle="collapse"
                    data-target={"#" + target}
                    aria-controls={target}
                    aria-expanded="false"
                >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            )}
        </>
    );
};

export default Burger;
