import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import ArticlesList from "../../containers/ArticlesList/ArticlesList";
import Loader from "../../components/Loader/Loader";

import { setActiveSectionNav } from "../../redux/actions/sectionNav";
import { lang } from "../../assets/js/variables";
import { LangContext } from '../../context/LanguageContext';

const HomePage = () => {
    const dispatch = useDispatch();
    const [articlesState, setArticlesState] = useState([]);
    const [isError, setIsError] = useState(false);
    const { language } = useContext(LangContext);

    useEffect(() => {
        dispatch(setActiveSectionNav("rsw"));
        axios
            .get(process.env.REACT_APP_API + lang[language].ARTICLES_LIST)
            .then(function (response) {
                setArticlesState(response.data.articles);
            })
            .catch(function (error) {
                if (error.response && error.response.data.message) {
                    setIsError(error.response.data.message);
                } else {
                    setIsError("error");
                }
            });
        // eslint-disable-next-line
    }, []);

    if (isError || !articlesState) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return <ArticlesList value={articlesState} />;
};

export default HomePage;
