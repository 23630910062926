import React from "react";
import { Link } from 'react-router-dom';

const Img = ({ value, link, target, router }) => {
    return (
        <div className="navItem__content navItem__content--img">
            {router ? (
                <Link to={link ? link : "/"}>
                    <img src={value} className="img-fluid" alt="" />
                </Link>
            ) : (
                <a href={link} target={target}>
                    <img src={value} className="img-fluid" alt="" />
                </a>
            )}
        </div>
    );
};

export default Img;
