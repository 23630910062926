export const fetchAside = (action) => ({
    type: "FETCH_ASIDE_SUCCESS",
    top: action.top ? action.top : null,
    middle: action.middle ? action.middle : null,
    bottom: action.bottom ? action.bottom : null,
});
export const clearAside = () => ({
    type: "CLEAR_ASIDE_SUCCESS",
});

export default {
    fetchAside,
    clearAside,
};
