import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useHandlers from "../../useHandlers/useHandlers";
import axios from "axios";

import { fetchRanking, setActiveRanking, rankingTableSetFullWidth, clearActiveRanking } from "../../../../redux/actions/ranking";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";

import Loader from "../../../../components/Loader/Loader";
import { LangContext } from '../../../../context/LanguageContext';

const RankingTable = ({ activeRank, slug }) => {
    const { SearchBar } = Search;
    const dispatch = useDispatch();
    const activeRankState = useSelector((state) => state.ranking.active);
    const rankingState = useSelector((state) => state.ranking);

    const translationState = useSelector((state) => state.translations);
    const {language } = useContext(LangContext)
    const [isHide, setIsHide] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState();

    const { toggleExtendedElements, columnsFormatter, options, sortOption, defaultSorted } = useHandlers({
        isHide,
    });

    const toggleHandlers = (type) => {
        if (type === 1) {
            setIsHide(!isHide);
        } else if (type === 2) {
            dispatch(rankingTableSetFullWidth(isHide));
            setIsHide(!isHide);
        }
    };

    useEffect(() => {
        if (activeRank) {
            if (!rankingState.data[activeRank]) {
                let endpoint = process.env.REACT_APP_API + "/rankings/" + slug;

                if (language === "en") {
                    endpoint = process.env.REACT_APP_API + "/en/rankings/" + slug;
                }

                axios
                    .get(endpoint)
                    .then(function (response) {
                        let data = {
                            key: activeRank,
                            data: response.data,
                        };
                        data.data.columns = columnsFormatter(response.data);

                        dispatch(fetchRanking(data));
                        dispatch(setActiveRanking(response.data));
                    })
                    .catch(function (error) {
                        if (error.response && error.response.data.message) {
                            setIsError(error.response.data.message);
                        } else {
                            setIsError("error");
                        }
                    });
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (activeRank && rankingState.data[activeRank]) {
            const data = rankingState.data[activeRank];
            data.columns = columnsFormatter(data);
            dispatch(setActiveRanking(data));
        }

        return () => {
            dispatch(clearActiveRanking());
            dispatch(rankingTableSetFullWidth(false));
            setIsHide(false);
            setIsLoading(true);
        };
        // eslint-disable-next-line
    }, [activeRank]);

    useEffect(() => {
        toggleExtendedElements();
        // eslint-disable-next-line
    }, [isHide]);

    useEffect(() => {
        if (activeRankState && activeRankState.name) {
            setIsHide(true);
            setIsLoading(false);
        }
    }, [activeRankState]);

    if (!activeRankState || isLoading) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return (
        <>
            <h4 className="rankingPage__title" onClick={() => columnsFormatter()}>
                {activeRankState.name}
            </h4>

            {activeRankState.tabs ? (
                <ul className="rankingPage__tabs">
                    {Object.keys(activeRankState.tabs).map((i, index) => (
                        <li className="rankingPage__tabsItem" key={index}>
                            {activeRankState.tabs[i]["target"] === "_self" ? (
                                <Link to={activeRankState.tabs[i]["url"] ? activeRankState.tabs[i]["url"] : "/"}>{activeRankState.tabs[i]["title"]}</Link>
                            ) : (
                                <a href={activeRankState.tabs[i]["url"]} target={activeRankState.tabs[i]["target"] ? activeRankState.tabs[i]["target"] : "_blank"} rel="noopener noreferrer">
                                    {activeRankState.tabs[i]["title"]}
                                </a>
                            )}
                        </li>
                    ))}

                    {activeRankState.settings.toggler && (
                        <li className="rankingPage__tabsItem rankingPage__tabsItem--imp" onClick={() => toggleHandlers(activeRankState.settings.toggler)}>
                            {language === "en" ? (
                                <>{isHide ? translationState.en.table.toggleShow : translationState.en.table.toggleHide}</>
                            ) : (
                                <>{isHide ? translationState.pl.table.toggleShow : translationState.pl.table.toggleHide}</>
                            )}
                        </li>
                    )}
                </ul>
            ) : (
                activeRankState.settings.toggler && (
                    <button className="rankingPage__tabs--btn rankingPage__toggle btn btn-outline-primary btn-sm" onClick={() => toggleHandlers(activeRankState.settings.toggler)}>
                        {language === "en" ? (
                            <>{isHide ? translationState.en.table.toggleShow : translationState.en.table.toggleHide}</>
                        ) : (
                            <>{isHide ? translationState.pl.table.toggleShow : translationState.pl.table.toggleHide}</>
                        )}
                    </button>
                )
            )}

            <div className="rankingPage__table">
                <PaginationProvider pagination={paginationFactory(options)}>
                    {({ paginationProps, paginationTableProps }) => (
                        <>
                            <PaginationListStandalone {...paginationProps} />
                            <ToolkitProvider keyField="key" columns={activeRankState.columns} data={activeRankState.rank} bootstrap4 search>
                                {(toolkitprops) => (
                                    <>
                                        <SearchBar
                                            {...toolkitprops.searchProps}
                                            placeholder={language === "en" ? translationState.en.table.searchBar : translationState.pl.table.searchBar}
                                            className="table__search"
                                        />

                                        <BootstrapTable
                                            hover
                                            striped
                                            condensed
                                            classes="w-auto"
                                            // rowClasses={rowClasses}
                                            headerClasses="table__header"
                                            wrapperClasses="table-responsive"
                                            sort={sortOption}
                                            defaultSorted={defaultSorted}
                                            {...toolkitprops.baseProps}
                                            {...paginationTableProps}
                                        />
                                    </>
                                )}
                            </ToolkitProvider>

                            <PaginationListStandalone {...paginationProps} />

                            <SizePerPageDropdownStandalone {...paginationProps} className="dropdownStandalone" />
                            <PaginationTotalStandalone {...paginationProps} />
                        </>
                    )}
                </PaginationProvider>
            </div>

            {activeRankState.settings.info && <div className="small mt-3" dangerouslySetInnerHTML={{ __html: activeRankState.settings.info }}></div>}

            <div className="mt-4">
                <strong>Oznaczenia:</strong>
                <br />= uczelnie sklasyfikowane na pozycjach ex aequo (o wynikach różniących się nie więcej niż 0,5 procent)
                {/* <br />* pozycja uczelni po korekcie */}
            </div>
        </>
    );
};

export default RankingTable;
