export const fetchRanking = (action) => ({
    type: "FETCH_RANKING_SUCCESS",
    data: action.data ? action.data : null,
    key: action.key ? action.key : null,
});
export const setActiveRanking = (action) => ({
    type: "SET_ACTIVE_RANKING_SUCCESS",
    data: action ? action : null,
});
export const clearActiveRanking = (action) => ({
    type: "CLEAR_ACTIVE_RANKING_SUCCESS",
});
export const rankingColumnsFormater = (action) => ({
    type: "RANKING_COLUMNS_FORMATER",
    columns: action ? action : null,
});
export const rankingTablePageChange = (action) => ({
    type: "RANKING_TABLE_PAGE_CHANGE",
    columns: action.columns ? action.columns : null,
    activePage: action.page ? action.page : null,
});
export const rankingTableSizePerPageChange = (action) => ({
    type: "RANKING_TABLE_SIZE_PER_PAGE_CHANGE",
    columns: action.columns ? action.columns : null,
    sizePerPage: action.sizePerPage ? action.sizePerPage : null,
});
export const rankingTableSetFullWidth = (action) => ({
    type: "RANKING_TABLE_SET_FULL_WIDTH",
    toggle: action ? action : null,
});

export default {
    fetchRanking,
    setActiveRanking,
    clearActiveRanking,
    rankingColumnsFormater,
    rankingTablePageChange,
    rankingTableSizePerPageChange,
    rankingTableSetFullWidth,
};
