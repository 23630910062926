import React from "react";
import "./style.scss";

// Sections for this page
import ArticleContent from "./Section/ArticleContent";
import ArticleImage from "./Section/ArticleImage";

function ArticlesList({ value, classic }) {
    if (!value) {
        return (
            <div className="alert alert-danger" role="alert">
                error
            </div>
        );
    }
    return (
        <div className={"blog"} data-classic={classic}>
            {value.map((article, index) => (
                <article key={index}>
                    <ArticleImage title={article.title} img={article.img} alt={article.title} slug={article.slug} />
                    <ArticleContent title={article.title} lead={article.lead} tag={article.tag} slug={article.slug} />
                </article>
            ))}
        </div>
    );
}

export default ArticlesList;
