import React, {useContext, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./style.scss";

import { fetchSectionNav, sectionNavError } from "../../../redux/actions/sectionNav";

import NavIte from "./Sections/NavIte";
import Loader from "../../../components/Loader/Loader";
import { Link } from 'react-router-dom';
import { LangContext } from "../../../context/LanguageContext";

const SectionNav = () => {
    const dispatch = useDispatch();
    const activeSectionNav = useSelector((state) => state.sectionNav.active);
    const sectionNavState = useSelector((state) => state.sectionNav.data);
    const sectionNav = sectionNavState[activeSectionNav];
    const { language } = useContext(LangContext)

    useEffect(() => {
        if (!sectionNav && activeSectionNav) {
            let sectionNav = "rsw";
            if (activeSectionNav) {
                sectionNav = activeSectionNav;
            }

            axios
                .get(process.env.PUBLIC_URL + "/data/" + language + "/sectionNav/" + sectionNav + ".json")
                .then(function (response) {
                    const data = {
                        key: activeSectionNav,
                        data: response.data,
                    };
                    dispatch(fetchSectionNav(data));
                })
                .catch(function () {
                    dispatch(sectionNavError("error"));
                });
        }
        // eslint-disable-next-line
    }, [activeSectionNav]);

    if (!sectionNav) {
        return (
            <div className="sectionNav">
                <div className="sectionNav__container justify-content-center">
                    <Loader size="2" />
                </div>
            </div>
        );
    }
    return (
        <div className="sectionNav">
            <div className="sectionNav__container">
                {sectionNav.img && (
                    <div className="sectionNav__img">
                        {sectionNav.img.url && sectionNav.img.url === "_self" ? (
                            <Link to={sectionNav.img.url ? sectionNav.img.url : "/"} className="navbar-brand">
                                <img src={sectionNav.img.src} className="img-fluid" alt={sectionNav.img.alt} />
                            </Link>
                        ) : sectionNav.img.url ? (
                            <a href={sectionNav.img.url} target={sectionNav.img.url ? sectionNav.img.url : "_blank"} rel="noopener noreferrer">
                                <img src={process.env.REACT_APP_FOLDER + sectionNav.img.src} className="img-fluid" alt={sectionNav.img.alt} />
                            </a>
                        ) : (
                            <img src={process.env.REACT_APP_FOLDER + sectionNav.img.src} className="img-fluid" alt={sectionNav.img.alt} />
                        )}
                    </div>
                )}
                <div className="sectionNav__nav">
                    {sectionNav.nav.map((navItem, index) => (
                        <div className={"sectionNav__navItem sectionNav__navItem--" + (navItem.size ? navItem.size : "large")} key={index}>
                            <div className="navItem" style={{ backgroundColor: navItem.color }}>
                                <NavIte data={navItem} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default SectionNav;
