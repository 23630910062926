import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { LangContext } from '../../../../../context/LanguageContext';

const Classic = ({ heding, value, number, redmore, link, target, router }) => {
  const { language } = useContext(LangContext);
  const translationSectionNavState = useSelector((state) => state.translations);
  const translationSectionNav = translationSectionNavState[language].sectionNav;

      return (
          <div className="navItem__content navItem__content--classic">
              {router ? (
                  <Link to={link ? link : "/"}>
                      {heding && <div className="heding">{heding}</div>}
                      {number && <div className="number">{number}</div>}

                      {value}
                      {redmore && <div className="redmore">{translationSectionNav && translationSectionNav.redmore}</div>}
                  </Link>
              ) : (
                  <a href={link} target={target}>
                      {heding && <div className="heding">{heding}</div>}
                      {number && <div className="number">{number}</div>}

                      {value}
                      {redmore && <div className="redmore">{translationSectionNav && translationSectionNav.redmore}</div>}
                  </a>
              )}
          </div>
      );
};

export default Classic;
