import React, { useState, useEffect } from "react";
import axios from "axios";

import Loader from "../../../components/Loader/Loader";

const Footer = () => {
    const [footerState, setFooterState] = useState();
    const [isError, setIsError] = useState();

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API + "/menu/rpqjtwatl3")
            .then(function (response) {
                setFooterState(response.data.items);
            })
            .catch(function (error) {
                if (error.response && error.response.data.message) {
                    setIsError(error.response.data.message);
                } else {
                    setIsError("error");
                }
            });
        // eslint-disable-next-line
    }, []);

    if (isError || !footerState) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return (
        <div className="footer pt-2 pt-md-4 pb-1">
            <div className="container">
                <div className="row">
                    {footerState.map((item, index) => (
                        <div className="col-12 col-sm-6 col-md-4" key={index}>
                            <h4 className="footer__sliderHead">
                                <i className="fas fa-caret-right d-md-none rotate"></i>
                                <span className="pl-2 pl-md-0">{item.name}</span>
                            </h4>
                            <ul className="footer__sliderBody list-unstyled d-md-block">
                                {item.sub_items &&
                                    item.sub_items.map((e, index) => (
                                        <li key={index}>
                                            {e.url ? (
                                                <a className={e.class} href={e.url} target={e.target}>
                                                    {e.name}
                                                </a>
                                            ) : (
                                                <>{e.name}</>
                                            )}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Footer;
