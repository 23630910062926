import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./style.scss";

import Loader from "../../components/Loader/Loader";
import { LangContext } from '../../context/LanguageContext';

const Cards = ({ value, aside }) => {
    const { language } = useContext(LangContext);
    const translationState = useSelector((state) => state.translations);

    if (!value) {
        return <Loader />;
    }
    return (
        <div className="cards">
            <div className="row">
                {value &&
                    value.map((card, index) => (
                        <div className={aside ? "col-6 col-md-12" : "col-md-6"} key={index}>
                            <div className="cards__item">
                                {card.slug ? (
                                    <Link to={card.slug}>
                                        {card.title && <h3 className={"cards__itemTitle"}>{card.title}</h3>}
                                    </Link>
                                ) : (
                                    <>{card.title && <h3 className={"cards__itemTitle"}>{card.title}</h3>}</>
                                )}

                                {card.value && card.order ? (
                                    <ol className="cards__list">
                                        {card.value.map((item, index) => (
                                            <li className="p-1 f-90" key={index}>
                                                {item.link ? <Link to={item.link}>{item.name}</Link> : <>{item.name}</>}
                                            </li>
                                        ))}
                                    </ol>
                                ) : card.value ? (
                                    <ul className="cards__list cards__list--ul">
                                        {card.value.map((item, index) => (
                                            <li className="f-90" key={index}>
                                                {item.link ? <Link to={item.link}>{item.name}</Link> : <>{item.name}</>}
                                            </li>
                                        ))}
                                    </ul>
                                ) : null}

                                {card.slug && (
                                    <div className="cards__more p-3 p-sm-0">
                                        <Link to={card.slug} className="f-80">
                                            {language === "en" ? (
                                                <>{card.more || translationState.en.global.more}</>
                                            ) : (
                                                <>{card.more || translationState.pl.global.more}</>
                                            )}
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default Cards;
