import React, {useContext} from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    rankingTablePageChange,
    rankingTableSizePerPageChange,
    // rankingColumnsFormater,
} from "../../../redux/actions/ranking";
import { LangContext } from "../../../context/LanguageContext"

const useHandlers = ({ isHide }) => {
    const dispatch = useDispatch();
    const rankState = useSelector((state) => state.ranking.active);
    const translationState = useSelector((state) => state.translations);
    const { language } = useContext(LangContext)

    const htmlFormatter = (cell) => {
        return <div dangerouslySetInnerHTML={{ __html: cell }} />;
    };

    const percentFormatter = (column) => {
        return (
            <div className="text-center">
                {column.text}
                <br />
                <span className="font-weight-light">{column.percent}</span>
            </div>
        );
    };

    const toggleExtendedElements = () => {
        var e = document.getElementsByClassName("toggle");

        for (var i = 0; i < e.length; i++) {
            if (isHide) {
                e[i].className += " d-none";
            } else {
                e[i].classList.remove("d-none");
            }
        }
    };

    const columnsFormatter = (data) => {
        const collumnsData = data.columns;
        let result;

        if (collumnsData) {
            result = collumnsData?.map(function (el) {
                if (el.htmlFormatter === true) {
                    let newData = Object.assign({}, el);
                    newData.formatter = htmlFormatter;
                    newData.headerTitle = () => el.description;

                    if (el.percent) {
                        newData.headerFormatter = percentFormatter;
                    }

                    return newData;
                } else {
                    let basicData = Object.assign({}, el);
                    basicData.headerTitle = () => el.description;
                    if (el.percent) {
                        basicData.headerFormatter = percentFormatter;
                    }
                    return basicData;
                }
            });
        }

        return result;
        // dispatch(rankingColumnsFormater(result));
    };

    const toggleFormatter = (data) => {
        const collumnsData = data;
        let result = collumnsData.map(function (el) {
            if (el.toggle === true) {
                let newData = Object.assign({}, el);
                if (isHide) {
                    newData.classes += " d-none";
                    newData.headerClasses += " d-none";
                } else {
                    newData.classes = "toggle";
                    newData.headerClasses = "toggle";
                }
                return newData;
            } else {
                let basicData = Object.assign({}, el);
                return basicData;
            }
        });
        return result;
    };

    // ------------------------------------------------------------------
    // --------------BootstrapTable settings START-----------------------
    // ------------------------------------------------------------------
    const tableRankLength = rankState ? rankState.rank.length : 0;

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total d-block small my-2">
            {language === "en" ? translationState.en.table.total : translationState.pl.table.total} {from}-{to} {language === "en" ? "of" : "z"} {size}
        </span>
    );

    const options = {
        custom: true,
        paginationSize: 3,
        pageStartIndex: 1,
        firstPageText: "<<",
        prePageText: "<",
        nextPageText: ">",
        lastPageText: ">>",
        nextPageTitle: " ",
        prePageTitle: " ",
        firstPageTitle: " ",
        lastPageTitle: " ",
        showTotal: true,
        sizePerPage: rankState ? rankState.settings.sizePerPage : null,
        page: rankState ? rankState.settings.activePage : 1,
        totalSize: tableRankLength,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [
            {
                text: "25",
                value: 25,
            },
            {
                text: "50",
                value: 50,
            },
            {
                text: "100",
                value: 100,
            },
        ],
        onPageChange: (page) => {
            let data = {
                columns: toggleFormatter(rankState.columns),
                page: page,
            };
            dispatch(rankingTablePageChange(data));
        },
        onSizePerPageChange: (sizePerPage) => {
            let data = {
                columns: toggleFormatter(rankState.columns),
                sizePerPage: sizePerPage,
            };
            dispatch(rankingTableSizePerPageChange(data));
        },
    };

    const sortOption = {
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
            if (typeof a !== "number") {
                if (a.split("-").length > 1 && a.split("-")[0]) {
                    a = a.split("-")[0];
                }
                a = a.replace("+", 0).replace("-", 9999).replace("=", "").replace("b.d.", 0);
            }

            if (typeof b !== "number") {
                if (b.split("-").length > 1 && b.split("-")[0]) {
                    b = b.split("-")[0];
                }
                b = b.replace("+", 0).replace("-", 9999).replace("=", "").replace("b.d.", 0);
            }

            if (order === "asc") return a - b;
            else return b - a;
        },
    };

    const defaultSorted = [
        {
            dataField: "place",
            order: "asc", //asc or desc
        },
    ];

    // ------------------------------------------------------------------
    // --------------BootstrapTable settings END-------------------------
    // ------------------------------------------------------------------

    return {
        options,
        sortOption,
        defaultSorted,
        columnsFormatter,
        toggleExtendedElements,
    };
};

export default useHandlers;
