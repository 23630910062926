import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import "./style.scss";

import ShareButton from "../../../../components/ShareButton/ShareButton";
import Loader from "../../../../components/Loader/Loader";

const RankingArticle = () => {
    const activeContentState = useSelector((state) => state.rankingPage.active.content);
    const [articleState, setArticleState] = useState();
    const [isError, setIsError] = useState();

    useEffect(() => {
        if (activeContentState.value) {
            axios
                .get(process.env.REACT_APP_API + "/article/" + activeContentState.value)
                .then(function (response) {
                    setArticleState(response.data);
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        setIsError(error.response.data.message);
                    } else {
                        setIsError("error");
                    }
                });
        }
    }, [activeContentState]);

    if (isError || !articleState) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return (
        <div className="rankingArticle">
            {articleState.img ? (
                <div className="rankingArticle__image">
                    <h2 className="rankingArticle__title text-uppercase p-1">{articleState.title}</h2>

                    {<img className="img-fluid" src={articleState.img} alt={articleState.alt || " "} />}
                </div>
            ) : (
                <h2 className="rankingArticle__title text-uppercase my-4">{articleState.title}</h2>
            )}

            <ShareButton />

            <div className="rankingArticle__content" dangerouslySetInnerHTML={{ __html: articleState.content }} />

            <ShareButton justify="justify-content-center" />
        </div>
    );
};

export default RankingArticle;
