export const lang = {
  pl: {
    RSW: process.env.REACT_APP_METHODOLOGY_RSW,
    RKS: process.env.REACT_APP_METHODOLOGY_RKS,
    RSI: process.env.REACT_APP_METHODOLOGY_RSI,
    ARTICLES_LIST: process.env.REACT_APP_ARTICLES_LIST,
    GALLERY: process.env.REACT_APP_GALLERY,
    BANNERS_BILLBOARD: process.env.REACT_APP_BANNERS_BILLBOARD,
    BANNERS_ASIDE: process.env.REACT_APP_BANNERS_ASIDE,
  },
  en: {
    RSW: process.env.REACT_APP_METHODOLOGY_RSW_EN,
    RKS: process.env.REACT_APP_METHODOLOGY_RKS_EN,
    RSI: process.env.REACT_APP_METHODOLOGY_RSI_EN,
    ARTICLES_LIST: process.env.REACT_APP_ARTICLES_LIST_EN,
    GALLERY: process.env.REACT_APP_GALLERY_EN,
    BANNERS_BILLBOARD: process.env.REACT_APP_BANNERS_BILLBOARD_EN,
    BANNERS_ASIDE: process.env.REACT_APP_BANNERS_ASIDE_EN,
  }
}
