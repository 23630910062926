import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style.scss";

const Billboard = ({ endpoint }) => {
    const [Billboard, setBillboard] = useState([]);
    const [isError, setIsError] = useState();

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API + "/banners/" + endpoint)
            .then(function (response) {
                setBillboard(response.data);
            })
            .catch(function (error) {
                if (error.response && error.response.data.message) {
                    setIsError(error.response.data.message);
                } else {
                    setIsError("error");
                }
            });
        // eslint-disable-next-line
    }, [endpoint]);

    // useEffect(() => {
    //     if (Billboard.length) {
    //         var i = document.getElementsByClassName("carousel-item");

    //         if (i && i[0]) {
    //             i[0].className += " active";
    //         }
    //     }
    // }, [Billboard]);

    if (isError || !Billboard) {
        return <div>{isError ? isError : "error"}</div>;
    }

    return (
        <div className="billboard">
            <div className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    {Billboard.map((e, index) => (
                        <div className={"billboard__itemContainer carousel-item " + (index === 0 ? "active" : "")} data-interval="2500" key={index}>
                            <a className="billboard__item" href={e.url} target={e.target}>
                                {e.description && <div className="billboard__item--label f-80">{e.description}</div>}
                                <img className="billboard__img img-fluid" src={e.file} alt="billboard" />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Billboard;
