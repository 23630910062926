import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import "./style.scss";

//componets
import Burger from "../../../components/Burger/Burger";
import flagPL from "../../../assets/img/nav/pl.gif";
import flagEN from "../../../assets/img/nav/en.gif";
import { LangContext } from '../../../context/LanguageContext';

const MainNav = ({ setSubNavToggle, subNavToggle }) => {
    const {language, setLang } = useContext(LangContext);
    // error
    // change language with dispatch
    // fix dropdown

    const mainNav = {
        navBrand: {
            link: "http://perspektywy.pl/portal/",
            img: "//i.perspektywy.pl/logo/perspektywy-140-36.png",
            target: "_blank",
            alt: "Perspektywy",
        },
        navItems: [
            {
                link_pl: null,
                link_en: null,
                title_pl: "Strona główna",
                title_en: "Home",
                type: "link",
            },
            {
                link_pl: "/ranking/ranking-uczelni-akademickich",
                link_en: "/ranking/university-ranking",
                title_pl: "Rankingi szkół wyższych",
                title_en: "Perspektywy University Ranking",
                type: "link",
            },
            {
                link_pl: "/ranking/ranking-kierunkow-studiow",
                link_en: "/ranking/ranking-by-subject",
                title_pl: "Ranking Kierunków Studiów",
                title_en: "Ranking by Subject",
                type: "link",
            },
            // {
            //     link_pl: "rankingi",
            //     link_en: "rankingi",
            //     title_pl: "Ranking",
            //     title_en: "Ranking",
            //     type: "dropdown",
            //     items: [
            //         {
            //             link_pl: "/ranking/ranking-uczelni-akademickich",
            //             link_en: "/ranking/university-ranking",
            //             title_pl: "Rankingi szkół wyższych",
            //             title_en: "Perspektywy University Ranking",
            //             type: "link",
            //         },
            //         {
            //             link_pl: "/ranking/ranking-kierunkow-studiow",
            //             link_en: "/ranking/ranking-by-subject",
            //             title_pl: "Ranking Kierunków Studiów",
            //             title_en: "Ranking by Subject",
            //             type: "link",
            //         },
            //         {
            //             link_pl: "/ranking/ranking-studiow-inzynierskich",
            //             link_en: "/ranking/ranking-engineering",
            //             title_pl: "Ranking Studiów Inżynierskich",
            //             title_en: "Engineering & Technology Ranking",
            //             type: "link",
            //         },
            //     ],
            // },
            {
                link_pl: "/article/kapitula-rankingu-2022r",
                link_en: "/article/the-ranking-board-2021",
                title_pl: "Kapituła",
                title_en: "Ranking Board",
                type: "link",
            },
            {
                link_pl: "/gallery",
                link_en: "/gallery",
                title_pl: "Galeria",
                title_en: "Gallery",
                type: "link",
            },
            {
                link_pl: "/contact",
                link_en: "/contact",
                title_pl: "Kontakt",
                title_en: "Contact",
                type: "link",
            },
        ],
    };
    return (
        <div className="mainNav__container">
            <nav className="mainNav navbar navbar-expand-md">
                {mainNav.navBrand && mainNav.navBrand.target === "_self" ? (
                    <Link to={language === "pl" ? mainNav.navBrand.link_pl || "/" : mainNav.navBrand.link_en || "/"} className="navbar-brand">
                        <img className="mainNav__img img-fluid" src={mainNav.navBrand.img} alt={mainNav.navBrand.alt} />
                    </Link>
                ) : (
                    <a href={language === "pl" ? mainNav.navBrand.link_pl : mainNav.navBrand.link_en} target="_blank" rel="noopener noreferrer">
                        <img className="mainNav__img img-fluid" src={mainNav.navBrand.img} alt={mainNav.navBrand.alt} />
                    </a>
                )}

                <Burger className="d-none d-md-block" onClick={setSubNavToggle} subNavToggle={subNavToggle} white />

                <Burger id="navbarBtn" target="mainMenu" white />

                <div className="navbar-collapse collapse" id="mainMenu">
                    <ul className="mainNav__nav navbar-nav ml-auto">
                        {mainNav.navItems.map((e, index) => (
                            <li className={"mainNav__itemContainer nav-item" + (e.type === "dropdown" ? " dropdown" : "")} key={index}>
                                {e.type === "hashLink" && (
                                    <HashLink smooth to={language === "pl" ? e.link_pl || "/" : e.link_en || "/"} className="mainNav__item nav-link">
                                        {language === "pl" ? e.title_pl : e.title_en}
                                    </HashLink>
                                )}
                                {e.type === "link" && (
                                    <Link to={language === "pl" ? e.link_pl || "/" : e.link_en || "/"} className="mainNav__item nav-link">
                                        {language === "pl" ? e.title_pl : e.title_en}
                                    </Link>
                                )}
                                {e.type === "dropdown" && (
                                    <>
                                        <div
                                            className="mainNav__item nav-link dropdown-toggle"
                                            id={"navbarDropdown" + e.link}
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            {language === "pl" ? e.title_pl : e.title_en}
                                        </div>
                                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby={"navbarDropdown" + e.link}>
                                            {e.items.map((event, index) => (
                                                <Link to={language === "pl" ? e.link_pl || "/" : e.link_en || "/"} className="dropdown-item" key={index}>
                                                    {language === "pl" ? event.title_pl : event.title_en}
                                                </Link>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </li>
                        ))}

                        <li className={"nav-item nav__language " + (language === "pl" ? "nav__language--disabled" : "")}>
                            <a className="p-2" href="//ranking.perspektywy.org/2021/"  onClick={(e) => setLang(e, 'pl')}>
                                <img data-toggle="tooltip" src={flagPL} alt="Polski (PL)" title="Polski (PL)" />
                            </a>
                        </li>

                        <li className={"nav-item nav__language " + (language === "en" ? "nav__language--disabled" : "")}>
                            <a className="p-2" href="//ranking.perspektywy.org/2021/" onClick={(e) => setLang(e, 'en')}>
                              <img data-toggle="tooltip" src={flagEN} alt="English (UK)" title="English (UK)" />
                            </a>
                        </li>

                    </ul>
                </div>
            </nav>
        </div>
    );
};

export default MainNav;
