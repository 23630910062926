import React, { useContext } from "react";
import { useSelector } from "react-redux";
import "./style.scss";

import AsideType from "./AsideType/AsideType";

import huaweiLogo from "../../assets/img/loga/huawei.jpg";
import opiLogo from "../../assets/img/loga/logo-opi-500x500.jpg";
import upLogo from "../../assets/img/loga/urzad-patentowy-500x500.jpg";
import elsevierLogo from "../../assets/img/loga/logo-elsevier-500x500.jpg";
import { LangContext } from '../../context/LanguageContext';

const Aside = () => {
    const { language } = useContext(LangContext);
    const asideState = useSelector((state) => state.aside);
    const translationAside = useSelector((state) => state.translations);
    const translationContent = translationAside[language].aside;

    const merytoryczni = [
        {
            link: "https://opi.org.pl/",
            logo: opiLogo,
            name: "OPI",
        },
        {
            link: "https://www.elsevier.com/pl-pl/solutions/scival",
            logo: elsevierLogo,
            name: "Elsevier",
        },
        {
            link: "https://uprp.gov.pl/pl",
            logo: upLogo,
            name: "Urząd Patentowy",
        },
    ];

    const partners = [
        {
            link: "https://consumer.huawei.com/pl/",
            logo: huaweiLogo,
            name: "HUAWEI",
        },
    ];

    return (
        <aside className="aside">
            <div className="aside__global mb-4">
                {translationContent && <h3 className="aside__globalTitle" dangerouslySetInnerHTML={{ __html: translationContent.global2 }} />}

                {partners &&
                    partners.map((e, index) => (
                        <a className="aside__globalItem" href={e.link} target="_blank" rel="noopener noreferrer" key={index}>
                            <img className="partnerLogo img-fluid" src={e.logo} alt={e.name} />
                        </a>
                    ))}

                <hr />
            </div>

            {asideState.top && (
                <div className="aside__top">
                    <AsideType type={asideState.top.type} value={asideState.top.value} />
                </div>
            )}

            {asideState.middle ? (
                <div className="aside__middle">
                    <AsideType type={asideState.middle.type} value={asideState.middle.value} />
                </div>
            ) : (
                <div className="aside__middle">
                    <AsideType type="banners" value={process.env.REACT_APP_BANNERS_ASIDE} />
                </div>
            )}

            {asideState.bottom && (
                <div className="aside__bottom">
                    <AsideType type={asideState.bottom.type} value={asideState.bottom.value} />
                </div>
            )}

            <div className="aside__global mt-4">
                {translationContent && <h3 className="aside__globalTitle" dangerouslySetInnerHTML={{ __html: translationContent.global1 }} />}

                {merytoryczni &&
                    merytoryczni.map((e, index) => (
                        <a className="aside__globalItem" href={e.link} target="_blank" rel="noopener noreferrer" key={index}>
                            <img className="partnerLogo img-fluid" src={e.logo} alt={e.name} />
                        </a>
                    ))}
            </div>
        </aside>
    );
};

export default Aside;
