import React, { useEffect, useState } from "react";
import axios from "axios";

import Loader from "../../../components/Loader/Loader";

const TypeBanners = ({ value }) => {
    const [banners, setBanners] = useState();
    const [isError, setIsError] = useState();

    useEffect(() => {
        console.log(value);
        axios
            .get(process.env.REACT_APP_API + "/banners/" + value)
            .then(function (response) {
                setBanners(response.data);
            })
            .catch(function (error) {
                if (error.response && error.response.data.message) {
                    setIsError(error.response.data.message);
                } else {
                    setIsError("error");
                }
            });
        // eslint-disable-next-line
    }, [value]);

    if (!banners) {
        return <>{isError ? null : <Loader />}</>;
    }
    return (
        <>
            {banners && (
                <div className="row aside__bannners">
                    {banners.map((banner, index) => (
                        <div className="col-6 col-md-4 col-lg-12 text-center my-2" key={index}>
                            <a
                                className="d-block"
                                href={banner.url}
                                target={banner.target || "_blank"}
                                rel="noopener noreferrer"
                            >
                                <img className="img-fluid" src={banner.file || ""} alt={banner.alt || ""} />
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default TypeBanners;
