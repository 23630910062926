import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { LangContext } from '../../../context/LanguageContext';

const ArticleContent = ({ title, slug, tag, lead, readmore }) => {
    const { language } = useContext(LangContext);
    const translationState = useSelector((state) => state.translations);

    return (
        <div className="articlesList__content">
            <h2 className="articlesList__title mb-3">
                <Link to={`/article/${slug}`}>{title}</Link>
            </h2>

            <div className="articlesList__text">
                <div dangerouslySetInnerHTML={{ __html: lead }} />
            </div>

            {tag && (
                <div className="articlesList__badge">
                    <ul className="list-unstyled d-flex mb-0">
                        <li className="mr-1">
                            <span className="badge badge-primary">{tag}</span>
                        </li>
                    </ul>
                </div>
            )}

            <p className="articlesList__readmore text-right mb-0">
                <Link to={"/article/" + slug} className="f-80">
                    {language === "en" ? (
                        <>{readmore || translationState.en.global.more}</>
                    ) : (
                        <>{readmore || translationState.pl.global.more}</>
                    )}
                </Link>
            </p>
        </div>
    );
};

export default ArticleContent;
