import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "./style.scss";

import { fetchRankingPage, setActiveRankingPage, clearActiveRankingPage, rankingPageError } from "../../redux/actions/rankingPage";
import { setActiveSectionNav, clearActiveSectionNav } from "../../redux/actions/sectionNav";
import { fetchAside, clearAside } from "../../redux/actions/aside";

import RankingTable from "./Sections/RankingTable/RankingTable";
import RankingArticle from "./Sections/RankingArticle/RankingArticle";
import RankingCards from "./Sections/RankingCards/RankingCards";
import Loader from "../../components/Loader/Loader";

import Billboard from "../../components/Billboard/Billboard";

const RankingPage = ({ match }) => {
    const { rank, category, type } = match.params;
    const dispatch = useDispatch();
    const rankState = useSelector((state) => state.rankingPage);
    const [isLoading, setIsLoading] = useState(true);
    const [slug, setSlug] = useState();

    useEffect(() => {
        if (type) {
            setSlug(type);
        } else if (category) {
            setSlug(category);
        } else {
            setSlug(rank);
        }
    }, [rank, category, type]);

    useEffect(() => {
        if (slug) {
            setIsLoading(true);
            if (rankState.data[slug]) {
                dispatch(setActiveRankingPage(rankState.data[slug]));
                rankState.data[slug].sectionNav && dispatch(setActiveSectionNav(rankState.data[slug].sectionNav));
                rankState.data[slug].aside ? dispatch(fetchAside(rankState.data[slug].aside)) : dispatch(fetchAside(""));

                setIsLoading(false);
            } else {
                axios
                    .get(process.env.REACT_APP_API + "/ranking/page/" + process.env.REACT_APP_YEAR + "/" + slug)
                    .then(function (response) {
                        const data = {
                            key: slug,
                            data: response.data,
                        };

                        dispatch(fetchRankingPage(data));
                        dispatch(setActiveRankingPage(response.data));
                        response.data.sectionNav && dispatch(setActiveSectionNav(response.data.sectionNav));
                        response.data.aside ? dispatch(fetchAside(response.data.aside)) : dispatch(fetchAside(""));

                        setIsLoading(false);
                    })
                    .catch(function (error) {
                        if (error.response && error.response.data.message) {
                            dispatch(rankingPageError(error.response.data.message));
                        } else {
                            dispatch(rankingPageError("error"));
                        }
                        dispatch(fetchAside(""));
                    });
            }
        }

        return () => {
            dispatch(clearActiveRankingPage());
            dispatch(clearActiveSectionNav());
            dispatch(clearAside());
        };
        // eslint-disable-next-line
    }, [slug]);

    if (isLoading) {
        return (
            <>
                {rankState.error ? (
                    <div className="alert alert-danger" role="alert">
                        {rankState.error}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return (
        <>
            {rankState.active.topBanners && <Billboard endpoint={rankState.active.topBanners} />}
            {rankState.active.content && rankState.active.content.type && (
                <div className="rankingPage">
                    {rankState.active.content.type === "table" && <RankingTable activeRank={slug} slug={rankState.active.content.value} />}
                    {rankState.active.content.type === "cards" && <RankingCards />}
                    {rankState.active.content.type === "article" && <RankingArticle />}
                </div>
            )}
        </>
    );
};

export default RankingPage;
