import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

import ArticlesList from "../../containers/ArticlesList/ArticlesList";
import Loader from "../../components/Loader/Loader";

import { setActiveSectionNav } from "../../redux/actions/sectionNav";
import { lang } from "../../assets/js/variables";
import { LangContext } from '../../context/LanguageContext';

const Methodology = ({ match }) => {
    const dispatch = useDispatch();
    const { slug } = match.params;
    const [endpoint, setEndpoint] = useState();
    const [articlesState, setArticlesState] = useState([]);
    const [isError, setIsError] = useState(false);
    const { language } = useContext(LangContext);

    useEffect(() => {
        if (slug && slug === "rsw") {
            dispatch(setActiveSectionNav("rsw"));
            setEndpoint(process.env.REACT_APP_API + lang[language].RSW);
        } else if (slug && slug === "rks") {
            dispatch(setActiveSectionNav("rks"));
            setEndpoint(process.env.REACT_APP_API + lang[language].RKS);
        } else if (slug && slug === "rsi") {
            dispatch(setActiveSectionNav("rsi"));
            setEndpoint(process.env.REACT_APP_API + lang[language].RSI);
        }
        // eslint-disable-next-line
    }, [language]);

    useEffect(() => {
        if (endpoint) {
            axios
                .get(endpoint)
                .then(function (response) {
                    setArticlesState(response.data.articles);
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        setIsError(error.response.data.message);
                    } else {
                        setIsError("error");
                    }
                });
        }
        // eslint-disable-next-line
    }, [endpoint, language]);

    if (isError || !articlesState) {
        return (
            <>
                {isError ? (
                    <div className="alert alert-danger" role="alert">
                        {isError}
                    </div>
                ) : (
                    <Loader />
                )}
            </>
        );
    }
    return <ArticlesList value={articlesState} classic />;
};

export default Methodology;
