import React, { useContext } from "react";
import { useSelector } from "react-redux";
import "./style.scss";

import Aside from "../Aside/Aside";
import Billboard from "../../components/Billboard/Billboard";
import { lang } from '../../assets/js/variables'
import { LangContext } from '../../context/LanguageContext';

const MainContent = ({ children }) => {
    const rankingToggle = useSelector((state) => state.ranking.toggle);
    const { language } = useContext(LangContext);

    return (
        <div className="mainContent container-fluid" data-rankingtoggle={rankingToggle}>
            <div className="row">
                <div className={rankingToggle ? "col-12" : "col-12 col-lg-8 col-xl-9"}>
                    <main>{children}</main>

                    {lang[language].BANNERS_BILLBOARD && (
                        <div className="mt-5">
                            <Billboard endpoint={lang[language].BANNERS_BILLBOARD} />
                        </div>
                    )}
                </div>
                <div className={rankingToggle ? "col-12" : "col-12 col-lg-4 col-xl-3"}>
                    <Aside />
                </div>
            </div>
        </div>
    );
};

export default MainContent;
