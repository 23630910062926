import React from "react";
import { Link } from 'react-router-dom';

const ArticleImage = ({ slug, title, img, alt }) => (
    <div className="articlesList__image">
        {slug ? (
            <h2 className="articlesList__title text-uppercase p-1">
                <Link to={`/article/${slug}`}>{title}</Link>
            </h2>
        ) : (
            <h2 className="articlesList__title text-uppercase p-1">{title}</h2>
        )}

        {slug ? (
            <Link to={`/article/${slug}`}>
                <>
                    {img ? (
                        <img className="img-fluid img--zoom" src={img} alt={alt} />
                    ) : (
                        <img className="img-fluid" src={process.env.REACT_APP_FOLDER + "img/error-img.png"} alt={alt} />
                    )}
                </>
            </Link>
        ) : (
            img && <img className="img-fluid" src={img} alt={alt || ""} />
        )}
    </div>
);

export default ArticleImage;
