import React from "react";

// types:
import Classic from "./Types/Classic";
import Icon from "./Types/Icon";
import Img from "./Types/Img";

const NavIte = ({ data }) => {
    return (
        <>
            {data.type === "classic" && <Classic {...data} />}
            {data.type === "icon" && <Icon {...data} />}
            {data.type === "img" && <Img {...data} />}
        </>
    );
};

export default NavIte;
