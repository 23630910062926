import React, { useCallback, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "./App.scss";

//appHandlers
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

// Sections for this page
import Billboard from "../components/Billboard/Billboard";
import MainNav from "../containers/Nav/MainNav/MainNav";
import SubNav from "../containers/Nav/SubNav/SubNav";
import SectionNav from "../containers/Nav/SectionNav/SectionNav";
import MainContent from "../containers/MainContent/MainContent";
import AppFooter from "../containers/Footer/AppFooter";

// Views for this page
import HomePage from "./homePage/homePage";
import Methodology from "./methodology/Methodology";
import Article from "../containers/Article/Article";
import RankingPage from "../containers/RankingPage/RankingPage";
import Contact from "./contact/Contact";
import Gallery from "./gallery/Gallery";
import { lang } from "../assets/js/variables";
import LangProvider from '../context/LanguageContext';

const App = () => {
    const history = useHistory();
    const [currentLang, setLang] = useState(localStorage.getItem('language') || 'pl');
    const [subNavToggle, setSubNavToggle] = useState(false);

    const handleChangeLanguage = useCallback((e, l) => {
      e.preventDefault();
      e.stopPropagation();
      localStorage.setItem('language', l);
      setLang(l);
      history.go(0);

      return false;
    }, [history]);

    return (
      <>
        <LangProvider value={{language: currentLang, setLang: handleChangeLanguage}}>
            <ScrollToTop />
            <div className="App">
                <div className="AppContent">
                    {lang[currentLang].BANNERS_BILLBOARD && <Billboard endpoint={lang[currentLang].BANNERS_BILLBOARD} />}
                    <MainNav setSubNavToggle={() => setSubNavToggle(!subNavToggle)} subNavToggle={subNavToggle} />
                    <SubNav subNavToggle={subNavToggle} />
                    <SectionNav />

                    <MainContent>
                        <Switch>
                            <Route path="/ranking/:rank/:category?/:type?" component={RankingPage} />
                            <Route path="/article/:article" component={Article} />
                            <Route path="/methodology/:slug" component={Methodology} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/gallery" component={Gallery} />
                            <Route exact path="/" component={HomePage} />

                            {/*<Route component={Redirect} />*/}
                        </Switch>
                    </MainContent>
                </div>
                <AppFooter />
            </div>
        </LangProvider>
      </>
    );
};

export default App;
